.App {
  text-align: center;
}

.App-logo {
  max-height: 300px;
  max-width: 60%;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 50px;
  font-size: calc(10px + 2vmin);
  color: #555555;
  font-family: "Comfortaa", cursive;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.subtitle {
  /* color: #555555; */
  color: darkblue;
  font-weight: bold;
  --minFontSize: 10px;
  --maxFontSize: 50px;
  --scaler: 3vw;
  font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
}

.contact {
  margin-top: 10px;
  margin-bottom: 40px;
  color: darkblue;
  font-weight: bold;
  font-size: clamp(16px, 3vw, 30px);
  text-decoration: none;
}

.logo-bas{
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.logo-box{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoFTB{
  max-height: 180px;
  max-width: 60%;
}
.logoCertif{
  max-height: 160px;
  max-width: 70%;
}
.texte-certificat{
  font-size: 14px;
  margin: 0;
  padding: 0 20px;
  max-width: 440px;
}
.certificat{
  font-size: 14px;
  text-decoration: none;
  margin-top: 10px;
}
@media (max-width: 600px) {
  .logo-bas{
    flex-direction: column;
  }
  .logo-box{
    margin-top: 30px;
    width: 80%;
  }
  .logoFTB{
    max-height: 180px;
    max-width: 50%;
  }
  .logoCertif{
    max-height: 160px;
    max-width: 70%;
  }
}